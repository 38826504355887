import Vue from 'vue'
import App from './App.vue'
import VModal from 'vue-js-modal'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faShoppingCart,faCartPlus,faTruck,faExclamationTriangle,faPoo,faSearch,faAngleDoubleDown,faWindowClose,faCircleNotch,faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faShoppingCart,faCartPlus,faTruck,faExclamationTriangle,faPoo,faSearch,faAngleDoubleDown,faWindowClose,faCircleNotch,faTrashAlt)

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.use(VModal, { dialog: true })
Vue.config.productionTip = false

new Vue({
  render: h => h(App),
}).$mount('#app')
