<template>
    <div class="box">
        <h2>{{ msg }}</h2>
    </div>
</template>

<script>
export default {
  name: 'Topic',
  props: {
    msg: String,
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.box {
    width:100%;
    background: #0e1520;
    margin-top:15px;
    border-bottom:#2C75FF;
    padding:1em 0;
    text-align: left;
}
</style>