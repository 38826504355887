<template>
  <div id="app">
    <v-dialog />
    <modal name="my-first-modal" class="modal-me" :scrollable="true" height="auto">
      <button class="right" @click="$modal.hide('my-first-modal')">
        <font-awesome-icon icon="window-close" />
      </button>
      <div class="full-info" v-if="fullInfo">
        <h2>{{fullInfo.name}}</h2>
        <img :src="fullInfo.image" :alt="fullInfo.name">
        <p>{{fullInfo.description}}</p>
        <table>
          <tbody>
            <tr><td>Price:</td><td>{{calculateText(fullInfo.price)}}</td></tr>
            <tr><td>Stock:</td><td>{{Math.max(fullInfo.stock - fullInfo.res,0)}}</td></tr>
            <tr><td>Reservations:</td><td>{{Math.max(fullInfo.res,0)}}</td></tr>
          </tbody>
        </table>
        <button type="button" class="green" v-on:click="addFullInfoToCart">
            <font-awesome-icon icon="cart-plus" />
        </button>
      </div>
      <div class="loading" v-else>
        <font-awesome-icon icon="circle-notch" spin />
      </div>
    </modal>
    <modal name="shopping-cart-modal" class="modal-me shop-cart" :scrollable="true" height="auto">
      <button class="right" @click="$modal.hide('shopping-cart-modal')">
        <font-awesome-icon icon="window-close" />
      </button>
      <h2>Reservation Cart</h2>
      <h3>Items</h3>
      <table>
        <thead>
          <tr><th>Name</th><th>Price</th><th>Remove</th></tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in cart" :key="item.id">
            <td>{{item.name}}</td>
            <td>{{calculateText(item.price)}}</td>
            <td><button type="button" v-on:click="cart.splice(index,1)"><font-awesome-icon icon="trash-alt" /></button></td>
          </tr>
        </tbody>
        <tfoot>
          <tr><td>Sum (always in €): </td><td>{{cart.reduce((acc,item) => acc + parseInt(item.price),0)/100}} €</td><td></td></tr>
        </tfoot>
      </table>
      <p>Prices do not include shipping or import duties. I ship from Germany.</p>
      <button type="button" class="green" v-on:click="checkOut" :disabled="cart.length == 0">
        Continue <font-awesome-icon icon="truck"/>
      </button>
    </modal>
    <modal name="check-out-modal" class="modal-me check-out" :scrollable="true" height="auto">
      <button class="right" @click="$modal.hide('check-out-modal')">
        <font-awesome-icon icon="window-close" />
      </button>
      <h2>Contact Details</h2>
      <label for="cName">Name:</label> <input type="text" maxlength="255" id="cName" v-on:keyup="submittable" v-on:change="submittable">
      <label for="cEMail">E-Mail:</label> <input type="email" maxlength="255" id="cEMail" v-on:keyup="submittable" v-on:change="submittable">
      <label for="cDiscord">Discord:</label> <input type="text" maxlength="64" id="cDiscord" v-on:keyup="submittable" v-on:change="submittable">
      <label for="cTelegram">Telegram:</label> <input type="text" maxlength="64" id="cTelegram" v-on:keyup="submittable" v-on:change="submittable">
      <label for="cTwitter">Twitter:</label> <input type="text" maxlength="64" id="cTwitter" v-on:keyup="submittable" v-on:change="submittable">
      <label for="cData">I've read and understood the <a target="_blank" href="privatenotice.html">Data protection Notice</a>.</label> <input type="checkbox" id="cData" v-on:change="submittable">
      <label for="cTOS">I've read and understood the <a target="_blank" href="tos.html">Terms of Service</a>.</label> <input type="checkbox" id="cTOS" v-on:change="submittable">
      <p>Name, both checkboxes and one of the possible ways of contact are required.</p>
      <p>This is only a temporary reservation. Each party can cancel this reservation for any reason.
        I will contact you for payment and shipping once the items are ready.
        You can cancel your expressed interest anytime before your items get shipped.
        Orders over 3 items will require a down payment.
        I will contact you when I'm ready to start to work on your items.</p>
      <p>I currently only accept PayPal as payment option.</p>
      <button type="button" class="green" v-on:click="commit" :disabled="submitDisabled">
        Submit Reservation
      </button>
    </modal>
    <header>
      <img alt="Logo" src="./assets/logo.svg">
      <h1>Bastler Plushies &amp; 3D Prints</h1>
      <div class="spacer"></div>
      <select v-model="euro">
        <option value="0">US Dollar $</option>
        <option value="1">Euro €</option>
      </select>
      <input type="text" id="search" placeholder="Search" v-on:keyup.enter="apiSearch">
      <button id="execSearch" v-on:click="apiSearch"><font-awesome-icon icon="search" /></button>
      <button id="cart" v-on:click="openShoppingCart">
        <font-awesome-icon icon="shopping-cart" />
      </button>
    </header>
    <main>
      <div class="fullh" v-if="searchResult !== false">
        <button class="right" @click="searchResult = false;">
          <font-awesome-icon icon="window-close" />
        </button>
        <Topic msg="Search"/>
        <h4>Currently closed <font-awesome-icon icon="poo" /></h4>
        <!--<item-list :items="searchResult" :doge="doge" :euro="euro" v-on:open-info="openDetails" v-on:add-cart="addCart"></item-list>-->
        <p v-if="searchResult.length == 0"><font-awesome-icon icon="exclamation-triangle" /> No items found.</p>
      </div>
      <div v-else>
        <FullScreenVideo :asset="fav" msg="Currently Closed" />
        <div class="clear"></div>
        <Topic msg="Shoulder Plushies"/>
        <h4>Currently closed <font-awesome-icon icon="poo" /></h4>
        <!--<item-list :items="items[0]" :doge="doge" :euro="euro" v-on:open-info="openDetails" v-on:add-cart="addCart"></item-list>
        <Topic msg="Standing Plushies"/>
        <item-list :items="items[1]" :doge="doge" :euro="euro" v-on:open-info="openDetails" v-on:add-cart="addCart"></item-list>
        <Topic msg="3D Prints"/>
        <item-list :items="items[2]" :doge="doge" :euro="euro" v-on:open-info="openDetails" v-on:add-cart="addCart"></item-list>
        <Topic msg="Drinking Glasses"/>
        <item-list :items="items[3]" :doge="doge" :euro="euro" v-on:open-info="openDetails" v-on:add-cart="addCart"></item-list>
        <Topic msg="Custom Plushies"/>
        <item-list :items="items[4]" :doge="doge" :euro="euro" v-on:open-info="openDetails" v-on:add-cart="addCart"></item-list>-->
        <Topic msg="Shipping"/>
        <p>Shipping inside the European Single Market is {{calculateText(1200)}}. Larger Packages cost {{calculateText(1400)}} - {{calculateText(1600)}}.</p>
        <p>Shipping prices for European Countries which are not part of the trading union are between {{calculateText(2000)}} and {{calculateText(3000)}}.</p>
        <p>Prices for other locations have to be calculatet on the day of shipment. The rates change a lot due to the current pandemic. Worldwide Shippment will be at least {{calculateText(3700)}}.</p>
      </div>

      <footer><a target="_blank" href="tos.html">Terms of Service</a> <a target="_blank" href="impressum.html">Site Notice</a> <a target="_blank" href="privatenotice.html">Data Protection Notice</a> </footer>
    </main>
  </div>
</template>

<script>
import FullScreenVideo from './components/FullScreenVideo.vue'
import Topic from './components/Topic.vue'
import axios from 'axios'
import querystring from 'querystring'
var favImport = require('./assets/fav.mp4')

export default {
  name: 'App',
  components: {
    FullScreenVideo,
    Topic,
  },
  data: function() {
    return {
      fav: favImport,
      items: [[],[],[],[],[]],
      doge: 0,
      euro: "1",
      cart: [],
      fullInfo: false,
      searchResult: false,
      submitDisabled: true,
    };
  },
  methods: {
    openDetails: function(id) {
      var self = this;
      self.fullInfo = false;
      self.$modal.show('my-first-modal');
      axios.get('https://plush.rainer-juergensen.de/api.php?mode=detail&id='+id).then(function(response) {
        var data = response.data;
        if(data.ok)
        {
          self.fullInfo = data.result;
          self.doge = data.doge;
        }
      }).catch(error => console.log(error));
    },
    calculateText: function(price) {
        var eur = (price / 100);
        if (this.euro === "1") {
            return eur + " €";
        } else {
            return (eur / this.doge) + " $"
        }
    },
    apiSearch: function() {
      var self = this;
      var Search = document.getElementById("search").value;
      axios.get('https://plush.rainer-juergensen.de/api.php?mode=search&s='+encodeURIComponent(Search)).then(function(response) {
        var data = response.data;
        if(data.ok)
        {
          self.searchResult = data.result;
          self.doge = data.doge;
        }
      }).catch(error => console.log(error));
    },
    openShoppingCart: function() {
      this.$modal.show('shopping-cart-modal');
    },
    addCart: function(item) {
      var self = this;
      this.cart.push(item);
      this.$modal.show('dialog', {
        title: 'Added '+item.name+' to reservation cart',
        text: 'Do you want to open your cart?',
        buttons: [
          {
            title: 'Continue Browsing',
            handler: () => {
              this.$modal.hide('dialog')
            }
          },
          {
            title: 'Show Cart',
            handler: function() {
              self.$modal.hide('dialog');
              self.openShoppingCart();
            }
          }
        ]
      });
    },
    addFullInfoToCart: function() {
      this.$modal.hide('my-first-modal');
      this.addCart(this.fullInfo);
    },
    checkOut: function() {
      this.$modal.hide('shopping-cart-modal');
      this.$modal.show('check-out-modal');
    },
    commit: function() {
      //force Update
      this.submittable();
      if(! this.submitDisabled) {
        var name = document.getElementById("cName").value;
        var email = document.getElementById("cEMail").value;
        var discord = document.getElementById("cDiscord").value;
        var telegram = document.getElementById("cTelegram").value;
        var twitter = document.getElementById("cTwitter").value;
        var items = this.cart.map(function(v){return v.id;});
        axios.post("https://plush.rainer-juergensen.de/api.php?mode=reserve",querystring.stringify({
          name: name,
          email: email,
          discord: discord,
          telegram: telegram,
          twitter: twitter,
          items: items.join(","),
        })).then(function(response) {
        var data = response.data;
        if(data.ok)
        {
          window.location.href = "https://plush.rainer-juergensen.de/show.php?key="+data.result;
        }
      }).catch(error => console.log(error));
        this.$modal.hide('check-out-modal');
      }
    },
    submittable: function() {
      try {
        this.submitDisabled = !( (document.getElementById("cName").value.length >= 3) &&
        (
          (document.getElementById("cEMail").value.length >= 3) ||
          (document.getElementById("cDiscord").value.length >= 3) ||
          (document.getElementById("cTelegram").value.length >= 3) ||
          (document.getElementById("cTwitter").value.length >= 3)
        ) && document.getElementById("cData").checked && document.getElementById("cTOS").checked);
      } catch {
        this.submitDisabled =  true;
      }
    }
  },
  mounted() {
    var self = this;
    axios.get('https://plush.rainer-juergensen.de/api.php?mode=list').then(function(response) {
      var data = response.data;
      if(data.ok)
      {
        self.items = data.result;
        self.doge = data.doge;
      }
    }).catch(error => console.log(error));
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}
.modal-me .vm--modal, .vue-dialog.vm--modal {
  background: #0e1520;
  border: 1px solid #2C75FF;
}
.vue-dialog button {
  border-radius:0;
  background: #d5b72f;
  color:#0e1520;
}
.vue-dialog button:hover {
  background: #956f2e;
}
.right {
  float:right;
}
.loading {
  text-align: center;
  font-size: 100px;
  margin-top: 100px;
}
.full-info {
  width:100%;
}
.full-info img {
  width:100%;
}
.full-info p {
  text-align: justify;
  margin: 15px;
}
.full-info table {
  margin: 15px;
}
.full-info td {
  text-align: right;
}
.green {
  width: 100%;
  background-color: #6cd52f;
}
.green:hover {
  background-color:#60952e;
}
.green:disabled {
  text-decoration: line-through;
  background-color:#546347;
}
.shop-cart h3 {
  text-align: left;
  margin: 15px;
}
.shop-cart table {
  width:100%;
}
.shop-cart tfoot td {
  border-top: 1px solid white;
}
.shop-cart thead th {
  border-bottom: 1px solid white;
}
.check-out label, .check-out input {
  margin: 4%;
  width: 40%;
  display: inline-block;
  vertical-align: middle;
}
body, html {
  background: #4d607a;
  color:#eadfc8;
  width:100%;
  height:100%;
  margin:0;
  padding:0;
}
header {
  background: #0e1520;
  border-bottom: 1px solid #2C75FF;
  position: fixed;
  top:0;
  left:0;
  right:0;
  font-size: 1.4em;
  display: flex;
  z-index: 5;
}
header img {
  height:1.4em;
}
header h1 {
  font-size: 1.4em;
  margin:0;
}
@media only screen and (max-width: 800px) {
  header h1 {
    display: none;
  }
}
header button {
  margin-right: 10px;
}
header select {
  margin-right: 10px;
}
a {
  color:#d5b72f;
}
a:hover {
  color:#956f2e;
}
.fullh {
  margin-top: 5vh;
  min-height: 95vh;
}
button {
  border-radius:25px 0 25px 0;
	display:inline-block;
  text-align:center;
	vertical-align:middle;
  border-color:#2C75FF;
	border-width:1px;
	border-style:solid;
  background: #d5b72f;
  color:#0e1520;
  padding:0.6em;
}
button:hover, button:active {
  background: #956f2e;
}
input {
  background: #eadfc8;
  border:1px solid #2C75FF;
  color:#0e1520;
}
.spacer {
  flex-grow:4;
}
.clear {
  clear:both;
}
footer {
  margin-top:10px;
  box-sizing: border-box;
  background: #0e1520;
  border-top: 1px solid #2C75FF;
}
</style>
