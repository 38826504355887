<template>
    <div class="anchor">
        <video autoplay muted loop>
            <source v-bind:src="asset" type="video/mp4">
        </video>
        <div class="box">
            <h1>{{ msg }}</h1>
        </div>
        <div class="scroll">
            <font-awesome-icon icon="angle-double-down" />
        </div>
    </div>
</template>

<script>
export default {
  name: 'FullScreenVideo',
  props: {
    msg: String,
    asset: String,
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.anchor {
    position:relative;
    max-height: 100vh;
    overflow: hidden;
}
video {
    width:100%;
    filter: grayscale(100%);
}
.box {
    position: absolute;
    bottom: 5vh;
    left:0;
    right:0;
}
.box h1 {
    font-size: 5vh;
    color:#0e1520;
    text-shadow:
   -1px -1px 0 #eadfc8,  
    1px -1px 0 #eadfc8,
    -1px 1px 0 #eadfc8,
     1px 1px 0 #eadfc8;
}
.scroll {
    position: absolute;
    bottom: 0;
    left:0;
    right:0;
    font-size: 5vh;
    animation: colorchange 8s linear 1s infinite; 
    -webkit-animation: colorchange 8s linear 0s infinite alternate; /* Chrome and Safari */
}
@keyframes colorchange
{
    0%   {color: cyan;}
    33%  {color: magenta;}
    66%  {color: yellow;}
    100% {color: cyan;}
}

@-webkit-keyframes colorchange /* Safari and Chrome - necessary duplicate */
{
    0%   {color: cyan;}
    33%  {color: magenta;}
    66%  {color: yellow;}
    100% {color: cyan;}
}
</style>